@import "bootstrap/bootstrap";

html {
  overflow-x: hidden;
}

body {
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.navbar-brand {
  img {
    height: 40px;
  }
}

a {
  color: #c8bea2;
}

#carouselEdelweissHome {
  .carousel-item {
    position: relative;

    .carousel-blacker {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(28, 28, 28, 0.5);
      z-index: 700;
    }

    .carousel-caption-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      z-index: 900;

      justify-content: center;
      align-items: center;
      flex-direction: column;


      h1 {
        color: #fff;

        text-align: center;
        font-size: 1rem;
        @media screen and (min-width: 1200px) {
          font-size: 3rem;
        }
      }

      p {
        color: #fff;
        text-align: center;
        font-size: .8rem;
        margin: 0 auto;
        @media screen and (min-width: 1200px) {
          font-size: 1.3rem;
          max-width: 70%;
        }
      }
    }
  }

  .carousel-indicators,
  .carousel-control-prev, .carousel-control-next {
    z-index: 905;
  }
}

footer {
  background-color: #404448;
  color: #fff;
  padding-top: 100px;
  padding-bottom: 100px;

  a {
    text-decoration: none;
    color: #fff4df;
    border-bottom: 1px dashed #fff;

    &:hover {
      color: #ffffff;
    }
  }

  .footer-title-small {
    color: #c8bea2;
    padding: 1rem 0;
    text-transform: uppercase;
  }

  .footer-title-large {
    font-size: 2rem;
  }

  .footer-title-medium {
    font-size: 1.5rem;
  }

  .address {
    padding-top: 2rem;

    .address-title {
      color: #9ea0a5;
    }
  }

  .text {
    font-size: 0.9rem;
  }

  .footer-menu {
    list-style-type: square;

    > li {
      margin: 10px 0;

      a {
        border: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.navbar-nav {

  @media screen and (min-width: 992px) {
    .dropdown:hover .dropdown-menu {
      display: block;
    }
  }

  .menu-item {
    line-height: 20px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 3px;
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
      font-size: 12px;
    }

  }


  .menu-item {
    position: relative;


    &:after {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      background: #cabe9f;
      height: 2px;
      width: 0;
      transition: width .5s;

      @media screen and (min-width: 768px) {
        left: 1rem;
      }
    }


  }
  
  .menu-item:not(.menu-item-has-children) {
    &:after {
      display: block;
    }
  }

  > .menu-item:hover,
  > .current-menu-item,
  > .current-menu-parent {
    position: relative;

    a.nav-link {
      color: #111;
    }

    &:after {
      width: 30px;
    }

  }

  .dropdown-menu {
    background-color: #494c4f;
    margin-top: -5px;
    opacity: .9;

    .show {
      display: none;
    }

    .menu-item:after {
      display: none;
    }

    .dropdown-item {
      color: #fff;
      text-transform: capitalize;
      padding-right: 100px;

      &:focus,
      &:hover {
        background-color: #36383a;
        color: #cabe9f;
      }
    }
  }
}

.info-apartamente {
  margin-bottom: 3rem;
  position: relative;

  a.apartment-list-item {
    color: #cabe9f;
    text-decoration: none;
    transition: all ease .5s;

    h3 {
      font-weight: bold;
      color: #d31f3f;
    }

    h5 {
      color: #111;
    }

    img {
      filter: grayscale(70%);
      transition: all ease .5s;
    }

    &:hover,
    &:focus {
      color: #e3d493;

      img {
        filter: grayscale(0%);
      }
    }

    &.is-sold {
      color: #ccc !important;
      h3, h5 {
        color: #ccc;
      }
    }

    .apartment-sold {
      position: absolute;
      top: 180px;
      background: red;
      color: white;
      z-index: 800;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 50%;
      opacity: 0.8;
      transform: rotate(-5deg);
      border-radius: 3px;
      p {
        margin-bottom: 0;
        padding: 10px;
        font-weight: bold;
        font-size: 25px;
        text-align: center;
      }
    }
  }
}

.apartment-image {
  max-width: 800px;
  height: auto;
  @media screen and (max-width: 700px) {
    max-width: 100% !important;
  }
}

.light.cc_dialog button.cc_b_ok {
  background-color: #e49f58 !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.cc_dialog.light button.cc_b_cp {
  margin-left: 0 !important;
}

.apartment-single-info {


  .apartment-sold {
    position: relative;
    background: red;
    color: white;
    z-index: 800;
    margin: 0 auto;
    width: 50%;
    opacity: 0.8;
    border-radius: 3px;
    display: inline-block;
    p {
      margin-bottom: 0;
      padding: 10px;
      font-weight: bold;
      font-size: 25px;
      text-align: center;
    }
  }
}